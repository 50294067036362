import React, { useEffect, useState } from 'react';
import {
  Paper,
  Grid,
  Typography,
  makeStyles,
  TextField,
  Button,
  Chip,
} from '@material-ui/core';
import IPax from '../../common/types/IPax';
import createFullName from '../../common/helpers/createFullName';
import FormTextField from '../../common/forms/FormTextField';
import styles from './PaxOperationalInfo.styles';
import PaxStatus, {
  isTakingCapacity,
  paxStatusLabels,
} from '../enums/paxStatus';
import classNames from 'classnames';
import { OnChange } from 'react-final-form-listeners';
import { useField, useForm } from 'react-final-form';
import IExtraService from '../../common/types/IExtraService';
import IPaxExtraService from '../../common/types/IPaxExtraService';
import PaxExtraServiceStatus, {
  paxExtraServiceStatusLabels,
} from '../enums/paxExtraServiceStatus';
import { useUser } from '../../common/contexts/UserContext';
import Role from '../../common/enums/Role';
import TooltipIcon from '../../common/components/TooltipIcon';
const useStyles = makeStyles(styles);
const CABIN_NET_PERCENT = 0.73;
const ACTIVITY_NET_PERCENT = 0.9;

interface IProps {
  pax: IPax;
  paxKey: string;
  paxExtraServices: IPaxExtraService[];
  extraServices: IExtraService[];
}

const DAY_PADDLE_BOARDING_NAME = 'Day Paddle';
const STANDUP_PADDLE_BOARDING_NAME = 'Stand-up Paddleboarding';

let PaxOperationalInfo: React.FC<IProps> = ({
  pax,
  paxKey,
  paxExtraServices,
  extraServices,
}) => {
  const form = useForm();
  const classes = useStyles();
  const user = useUser();

  const [campingStatus, setCampingStatus] = useState<
    PaxExtraServiceStatus | null | undefined
  >(null);

  const [kayakingStatus, setKayakingStatus] = useState<
    PaxExtraServiceStatus | null | undefined
  >(null);

  const [photographyStatus, setPhotographyStatus] = useState<
    PaxExtraServiceStatus | null | undefined
  >(null);

  const [dayPaddleStatus, setDayPaddleStatus] = useState<
    PaxExtraServiceStatus | null | undefined
  >(null);
  const [standupPaddleStatus, setStandupPaddleStatus] = useState<
    PaxExtraServiceStatus | null | undefined
  >(null);

  const paxDetailsContainerClassName = classNames({
    [classes.paxDetailsContainer]: true,
    [classes.paxDetailsContainerCancelled]: !isTakingCapacity(pax.status),
  });

  const [totalGrossPrice, setTotalGrossPrice] = useState<number>(0);
  const [totalNetPrice, setTotalNetPrice] = useState<number>(0);

  const {
    input: { value: campingSellPriceValue },
  } = useField(`${paxKey}.campingSellPrice`);

  const {
    input: { value: kayakingSellPriceValue },
  } = useField(`${paxKey}.kayakingSellPrice`);

  const {
    input: { value: photographySellPriceValue },
  } = useField(`${paxKey}.photographySellPrice`);

  const {
    input: { value: dayPaddleSellPriceValue },
  } = useField(`${paxKey}.dayPaddleSellPrice`);

  const {
    input: { value: standupPaddleSellPriceValue },
  } = useField(`${paxKey}.standupPaddleSellPrice`);

  const {
    input: { value: sellPriceValue },
  } = useField(`${paxKey}.sellPrice`);

  const {
    input: { value: netPriceValue },
  } = useField(`${paxKey}.netPrice`);

  const {
    input: { value: campingNetPriceValue },
  } = useField(`${paxKey}.campingNetPrice`);

  const {
    input: { value: isNetPriceOverriden },
  } = useField(`${paxKey}.netPriceOverride`);

  const {
    input: { value: kayakingNetPriceValue },
  } = useField(`${paxKey}.kayakingNetPrice`);

  const {
    input: { value: photographyNetPriceValue },
  } = useField(`${paxKey}.photographyNetPrice`);

  const {
    input: { value: dayPaddleNetPriceValue },
  } = useField(`${paxKey}.dayPaddleNetPrice`);

  const {
    input: { value: standupPaddleNetPriceValue },
  } = useField(`${paxKey}.standupPaddleNetPrice`);

  const isNetPriceEditable =
    user.hasRole(Role.OperationsManager) && isNetPriceOverriden;
  const isNetPriceOverrideButtonEnabled =
    user.hasRole(Role.OperationsManager) && !isNetPriceOverriden;

  /**
   *
   * @param fieldName name of the net price field
   * @param value value of the sell corresponding sell price
   * @param ratePercent value of % of sell price that is need to calc net price
   * sets the auto calculated value for the net price field based on the percent net rate
   */
  function calcNetPrice(fieldName: string, value: number, ratePercent: number) {
    if (!value) {
      form.mutators.setValue(`${paxKey}.${fieldName}`, null);
    } else {
      form.mutators.setValue(
        `${paxKey}.${fieldName}`,
        '' + value * ratePercent
      );
    }
  }

  useEffect(() => {
    setCampingStatus(getExtraServiceStatus('Camping'));
    setKayakingStatus(getExtraServiceStatus('Kayaking'));
    setPhotographyStatus(getExtraServiceStatus('Photography'));
    setDayPaddleStatus(getExtraServiceStatus(DAY_PADDLE_BOARDING_NAME));
    setStandupPaddleStatus(getExtraServiceStatus(STANDUP_PADDLE_BOARDING_NAME));
    recalcTotals();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extraServices.length, paxExtraServices]);

  const isCancelledOrExpired = (
    paxExtraServiceStatus: PaxExtraServiceStatus | null | undefined
  ): Boolean => {
    return (
      PaxExtraServiceStatus.ExpiredOption === paxExtraServiceStatus ||
      PaxExtraServiceStatus.Cancelled === paxExtraServiceStatus
    );
  };

  const isPaxExpiredOrCancelled = () => {
    return (
      pax.status === PaxStatus.Cancelled ||
      pax.status === PaxStatus.ExpiredOption
    );
  };

  const recalcTotals = () => {
    setTotalGrossPrice(
      (sellPriceValue && !isPaxExpiredOrCancelled() ? sellPriceValue : 0) +
        (campingSellPriceValue && !isCancelledOrExpired(campingStatus)
          ? campingSellPriceValue
          : 0) +
        (kayakingSellPriceValue && !isCancelledOrExpired(kayakingStatus)
          ? kayakingSellPriceValue
          : 0) +
        (photographySellPriceValue && !isCancelledOrExpired(photographyStatus)
          ? photographySellPriceValue
          : 0) +
        (dayPaddleSellPriceValue && !isCancelledOrExpired(dayPaddleStatus)
          ? dayPaddleSellPriceValue
          : 0) +
        (standupPaddleSellPriceValue &&
        !isCancelledOrExpired(standupPaddleStatus)
          ? standupPaddleSellPriceValue
          : 0)
    );

    setTotalNetPrice(
      (netPriceValue && !isPaxExpiredOrCancelled() ? netPriceValue : 0) +
        (campingNetPriceValue && !isCancelledOrExpired(campingStatus)
          ? campingNetPriceValue
          : 0) +
        (kayakingNetPriceValue && !isCancelledOrExpired(kayakingStatus)
          ? kayakingNetPriceValue
          : 0) +
        (photographyNetPriceValue && !isCancelledOrExpired(photographyStatus)
          ? photographyNetPriceValue
          : 0) +
        (dayPaddleNetPriceValue && !isCancelledOrExpired(dayPaddleStatus)
          ? dayPaddleNetPriceValue
          : 0) +
        (standupPaddleNetPriceValue &&
        !isCancelledOrExpired(standupPaddleStatus)
          ? standupPaddleNetPriceValue
          : 0)
    );
  };

  const getExtraServiceStatus = (
    name: String
  ): PaxExtraServiceStatus | null | undefined => {
    const extraService = extraServices?.find(
      (service) => service.name === name
    );

    if (extraService) {
      const paxExtraService = paxExtraServices?.find(
        (paxExtraService) =>
          paxExtraService?.extraServiceId === extraService.extraServiceId
      );

      if (paxExtraService) {
        return paxExtraService.status;
      }
    }
  };

  if (!paxExtraServices) {
    return null;
  }
  return (
    <Paper variant="outlined" className={paxDetailsContainerClassName}>
      <Grid className={classes.netPriceOverrideButtonGrid}>
        <Typography align="left">
          Ops Info -{' '}
          {createFullName(pax.firstName, pax.middleName, pax.surname)}
        </Typography>
        <TooltipIcon
          title={
            'This control lets you override Net prices calculated by the software. Once override mode is activated, system will not auto calculate Net price values for this pax'
          }
        >
          <Button
            size="small"
            variant="contained"
            disabled={!isNetPriceOverrideButtonEnabled}
            className={classes.netPriceOverrideButton}
            onClick={() => {
              form.mutators.setValue(`${paxKey}.netPriceOverride`, true);
            }}
          >
            Override Net Price
          </Button>
        </TooltipIcon>
      </Grid>

      <Typography align="left" className={classes.paxOpsCostFieldsGroupText}>
        Cabin
      </Typography>
      <Grid container direction="column">
        <Grid
          container
          direction="row"
          spacing={1}
          justifyContent="flex-start"
          md={12}
          item
        >
          <Grid item md={4}>
            <Typography align="left">Sell Price</Typography>
          </Grid>
          <Grid item md={4}>
            <Typography align="left">Net Price</Typography>
          </Grid>
          <Grid item md={4}>
            <Typography align="center">Status</Typography>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          spacing={1}
          justify-content="flex-start"
          md={12}
          item
        >
          <Grid item md={4}>
            <FormTextField
              name={`${paxKey}.sellPrice`}
              label=""
              className={classes.inputField}
              max={999_999.99}
              type="currency"
              toolTip="Sell Price USD"
            />
          </Grid>
          <Grid item md={4}>
            <FormTextField
              name={`${paxKey}.netPrice`}
              label=""
              className={classes.inputField}
              disabled={!isNetPriceEditable}
              max={999_999.99}
              type="currency"
              toolTip={
                'Cabin Net Price: ' +
                CABIN_NET_PERCENT * 100 +
                '% of the Cabin Sell Price USD'
              }
            />
          </Grid>
          <Grid item md={4}>
            <Chip
              style={{ marginTop: '20px' }}
              color={isPaxExpiredOrCancelled() ? 'default' : 'primary'}
              label={paxStatusLabels[pax.status]}
              variant="outlined"
            />
          </Grid>
          <OnChange name={`${paxKey}.sellPrice`}>
            {(value, previous) => {
              if (!isNetPriceEditable) {
                calcNetPrice('netPrice', value, CABIN_NET_PERCENT);
              }

              recalcTotals();
            }}
          </OnChange>{' '}
          <OnChange name={`${paxKey}.netPrice`}>
            {(value, previous) => {
              if (value !== previous) {
                recalcTotals();
              }
            }}
          </OnChange>
        </Grid>
        <Grid item md={4}>
          <FormTextField
            name={`${paxKey}.depositAmount`}
            label="Deposit Due"
            className={classes.inputField}
            max={999_999.99}
            type="currency"
            toolTip="Deposit Amount USD"
          />
        </Grid>
      </Grid>

      <Typography align="left" className={classes.paxOpsCostFieldsGroupText}>
        Extra Services
      </Typography>
      <Grid container direction="column">
        <Grid
          container
          direction="row"
          spacing={1}
          justify-content="flex-start"
          md={12}
          item
        >
          <Grid item md={4}>
            <Typography align="left">Sell Price</Typography>
          </Grid>
          <Grid item md={4}>
            <Typography align="left">Net Price</Typography>
          </Grid>
          <Grid item md={4}>
            <Typography align="center">Status</Typography>
          </Grid>
        </Grid>

        {campingStatus && (
          <Grid
            container
            direction="row"
            spacing={1}
            justify-content="flex-start"
            alignItems="center"
            md={12}
            item
          >
            <Grid item md={4}>
              <FormTextField
                name={`${paxKey}.campingSellPrice`}
                label="Camping"
                className={classes.inputField}
                max={999_999.99}
                type="currency"
                toolTip="Camping Sell Price USD"
              />
            </Grid>
            <Grid item md={4}>
              <FormTextField
                name={`${paxKey}.campingNetPrice`}
                label="Camping"
                className={classes.inputField}
                max={999_999.99}
                disabled={!isNetPriceEditable}
                type="currency"
                toolTip={
                  'Camping Net Price: ' +
                  ACTIVITY_NET_PERCENT * 100 +
                  '% of the Camping Sell Price USD'
                }
              />
            </Grid>

            <Grid item md={4}>
              <Chip
                color={
                  isCancelledOrExpired(campingStatus) ? 'default' : 'primary'
                }
                label={paxExtraServiceStatusLabels[campingStatus]}
                variant="outlined"
              />
            </Grid>

            <OnChange name={`${paxKey}.campingSellPrice`}>
              {(value, previous) => {
                if (value !== previous) {
                  if (!isNetPriceEditable) {
                    calcNetPrice(
                      'campingNetPrice',
                      value,
                      ACTIVITY_NET_PERCENT
                    );
                  }

                  recalcTotals();
                }
              }}
            </OnChange>
            <OnChange name={`${paxKey}.campingNetPrice`}>
              {(value, previous) => {
                if (value !== previous) {
                  recalcTotals();
                }
              }}
            </OnChange>
          </Grid>
        )}
        {kayakingStatus && (
          <Grid
            container
            direction="row"
            spacing={1}
            justify-content="flex-start"
            alignItems="center"
            md={12}
            item
          >
            <Grid item md={4}>
              <FormTextField
                name={`${paxKey}.kayakingSellPrice`}
                label="Kayaking"
                className={classes.inputField}
                max={999_999.99}
                type="currency"
                toolTip="Kayaking Sell Price USD"
              />
            </Grid>
            <Grid item md={4}>
              <FormTextField
                name={`${paxKey}.kayakingNetPrice`}
                label="Kayaking"
                className={classes.inputField}
                disabled={!isNetPriceEditable}
                max={999_999.99}
                type="currency"
                toolTip={
                  'Kayaking Net Price: ' +
                  ACTIVITY_NET_PERCENT * 100 +
                  '% of the Kayaking Sell Price USD'
                }
              />
            </Grid>
            <Grid item md={4}>
              <Chip
                color={
                  isCancelledOrExpired(kayakingStatus) ? 'default' : 'primary'
                }
                label={paxExtraServiceStatusLabels[kayakingStatus]}
                variant="outlined"
              />
            </Grid>

            <OnChange name={`${paxKey}.kayakingSellPrice`}>
              {(value, previous) => {
                if (value !== previous) {
                  if (!isNetPriceEditable) {
                    calcNetPrice(
                      'kayakingNetPrice',
                      value,
                      ACTIVITY_NET_PERCENT
                    );
                  }
                  recalcTotals();
                }
              }}
            </OnChange>
            <OnChange name={`${paxKey}.kayakingNetPrice`}>
              {(value, previous) => {
                if (value !== previous) {
                  recalcTotals();
                }
              }}
            </OnChange>
          </Grid>
        )}
        {photographyStatus && (
          <Grid
            container
            direction="row"
            spacing={1}
            justify-content="flex-start"
            alignItems="center"
            md={12}
            item
          >
            <Grid item md={4}>
              <FormTextField
                name={`${paxKey}.photographySellPrice`}
                label="Photography"
                className={classes.inputField}
                max={999_999.99}
                type="currency"
                toolTip="Photography Sell Price USD"
              />
            </Grid>
            <Grid item md={4}>
              <FormTextField
                name={`${paxKey}.photographyNetPrice`}
                label="Photography"
                className={classes.inputField}
                disabled={!isNetPriceEditable}
                max={999_999.99}
                type="currency"
                toolTip={
                  'Photography Net Price: ' +
                  ACTIVITY_NET_PERCENT * 100 +
                  '% of the Photography Sell Price USD'
                }
              />
            </Grid>
            <Grid item md={4}>
              <Chip
                color={
                  isCancelledOrExpired(photographyStatus)
                    ? 'default'
                    : 'primary'
                }
                label={paxExtraServiceStatusLabels[photographyStatus]}
                variant="outlined"
              />
            </Grid>

            <OnChange name={`${paxKey}.photographySellPrice`}>
              {(value, previous) => {
                if (value !== previous) {
                  if (!isNetPriceEditable) {
                    calcNetPrice(
                      'photographyNetPrice',
                      value,
                      ACTIVITY_NET_PERCENT
                    );
                  }
                  recalcTotals();
                }
              }}
            </OnChange>
            <OnChange name={`${paxKey}.photographyNetPrice`}>
              {(value, previous) => {
                if (value !== previous) {
                  recalcTotals();
                }
              }}
            </OnChange>
          </Grid>
        )}
        {dayPaddleStatus && (
          <Grid
            container
            direction="row"
            spacing={1}
            justify-content="flex-start"
            alignItems="center"
            md={12}
            item
          >
            <Grid item md={4}>
              <FormTextField
                name={`${paxKey}.dayPaddleSellPrice`}
                label={DAY_PADDLE_BOARDING_NAME}
                className={classes.inputField}
                max={999_999.99}
                type="currency"
                toolTip={DAY_PADDLE_BOARDING_NAME + ' Sell Price USD'}
              />
            </Grid>
            <Grid item md={4}>
              <FormTextField
                name={`${paxKey}.dayPaddleNetPrice`}
                label={DAY_PADDLE_BOARDING_NAME}
                className={classes.inputField}
                disabled={!isNetPriceEditable}
                max={999_999.99}
                type="currency"
                toolTip={
                  DAY_PADDLE_BOARDING_NAME +
                  ' Net Price: ' +
                  ACTIVITY_NET_PERCENT * 100 +
                  '% of the ' +
                  DAY_PADDLE_BOARDING_NAME +
                  ' Sell Price USD'
                }
              />
            </Grid>
            <Grid item md={4}>
              <Chip
                color={
                  isCancelledOrExpired(dayPaddleStatus) ? 'default' : 'primary'
                }
                label={paxExtraServiceStatusLabels[dayPaddleStatus]}
                variant="outlined"
              />
            </Grid>
            <OnChange name={`${paxKey}.dayPaddleSellPrice`}>
              {(value, previous) => {
                if (value !== previous) {
                  if (!isNetPriceEditable) {
                    calcNetPrice(
                      'dayPaddleNetPrice',
                      value,
                      ACTIVITY_NET_PERCENT
                    );
                  }
                  recalcTotals();
                }
              }}
            </OnChange>
            <OnChange name={`${paxKey}.dayPaddleNetPrice`}>
              {(value, previous) => {
                if (value !== previous) {
                  recalcTotals();
                }
              }}
            </OnChange>
          </Grid>
        )}
        {standupPaddleStatus && (
          <Grid
            container
            direction="row"
            spacing={1}
            justify-content="flex-start"
            alignItems="center"
            md={12}
            item
          >
            <Grid item md={4}>
              <FormTextField
                name={`${paxKey}.standupPaddleSellPrice`}
                label={STANDUP_PADDLE_BOARDING_NAME}
                className={classes.inputField}
                max={999_999.99}
                type="currency"
                toolTip={STANDUP_PADDLE_BOARDING_NAME + ' Sell Price USD'}
              />
            </Grid>
            <Grid item md={4}>
              <FormTextField
                name={`${paxKey}.standupPaddleNetPrice`}
                label={STANDUP_PADDLE_BOARDING_NAME}
                className={classes.inputField}
                disabled={!isNetPriceEditable}
                max={999_999.99}
                type="currency"
                toolTip={
                  STANDUP_PADDLE_BOARDING_NAME +
                  ' Net Price: ' +
                  ACTIVITY_NET_PERCENT * 100 +
                  '% of the ' +
                  STANDUP_PADDLE_BOARDING_NAME +
                  ' Sell Price USD'
                }
              />
            </Grid>
            <Grid item md={4}>
              <Chip
                color={
                  isCancelledOrExpired(standupPaddleStatus)
                    ? 'default'
                    : 'primary'
                }
                label={paxExtraServiceStatusLabels[standupPaddleStatus]}
                variant="outlined"
              />
            </Grid>
            <OnChange name={`${paxKey}.standupPaddleSellPrice`}>
              {(value, previous) => {
                if (value !== previous) {
                  if (!isNetPriceEditable) {
                    calcNetPrice(
                      'standupPaddleNetPrice',
                      value,
                      ACTIVITY_NET_PERCENT
                    );
                  }
                  recalcTotals();
                }
              }}
            </OnChange>
            <OnChange name={`${paxKey}.standupPaddleNetPrice`}>
              {(value, previous) => {
                if (value !== previous) {
                  recalcTotals();
                }
              }}
            </OnChange>
          </Grid>
        )}

        <Typography align="left" className={classes.paxOpsCostFieldsGroupText}>
          Total
        </Typography>

        <Grid
          container
          direction="row"
          spacing={1}
          justify-content="space-around"
          md={12}
          item
        >
          <TextField
            value={'$' + Math.round(totalGrossPrice * 100) / 100}
            style={{ minWidth: '200px' }}
            margin="normal"
            label={'Gross Price'}
            disabled={true}
          />

          <TextField
            style={{ marginLeft: 60 }}
            value={'$' + Math.round(totalNetPrice * 100) / 100}
            margin="normal"
            label={'Net Price'}
            disabled={true}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        spacing={1}
        justify-content="flex-start"
        md={12}
        item
      >
        <Grid item md={4}>
          <FormTextField
            name={`${paxKey}.notes`}
            label="Notes"
            maxLength={500}
            multiline={true}
            rowsMax={4}
            className={classes.inputFieldNotes}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PaxOperationalInfo;
